import { useMutation, useQueryClient  } from "react-query";
import { apiInstance } from "../../api/instance";

const assignJudgeDisput = async ({ debateId, judgeId }) => {
  const response = await apiInstance.post(`/admin/${debateId}/disputed/assing-judge`, {
    judgeId,
  });
  return response.data;
};

export const useAssignJudgeDisput = () => {
  const queryClient = useQueryClient();

  return useMutation(assignJudgeDisput, {
    onSuccess: (_, { debateId }) => {
      queryClient.refetchQueries(["debateDetails", debateId]);
    },
    onError: (error) => {
      console.error("Failed assign judge:", error);
    },
  });
};
