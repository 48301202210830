import React, { useEffect, useState } from "react";
import axios from "axios";
import { Alert } from "@mui/material";

import { useParams, useNavigate } from "react-router-dom";
import { tokenService } from "./../services/tokenService";

import { useMe, useConcede } from "../hooks";

function DebateParticipantDetails() {
  const { debateId } = useParams();
  
  const [error, setError] = useState("");
  const [debate, setDebate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(null);

  const navigate = useNavigate();

  const { data: user, isLoading } = useMe();

  const {
    mutate: concede,
    isLoading: concedeIsLoading,
    isError: concedeIsError,
  } = useConcede();

  useEffect(() => {
    const fetchDebateDetails = async () => {
      try {
        let token = tokenService.getToken();

        if (!token) {
          const userData = tokenService.getUserData();
          token = userData?.token;
        }

        if (!token) {
          setError("Token not found. Please log in.");
          setLoading(false);
          return;
        }

        const response = await axios.get(
          `https://polemic.club/api/debates/${debateId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.status === 200) {
          setDebate(response.data);
        } else {
          setError("Failed to load debate details");
        }
        setLoading(false);
      } catch (err) {
        setError("Error while fetching debate details: " + err.message);
        setLoading(false);
      }
    };

    fetchDebateDetails();
  }, [debateId]);

  const handleConcede = async () => {
    concede(
      { debateId },
      {
        onSuccess: (response) => {
          setAlertMessage(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert
                severity="success"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                You have successfully conceded. Winner: {response.winner}
              </Alert>
            </div>
          );
          setTimeout(() => {
            navigate("/");
          }, 3000);
        },
        onError: (error) => {
          setAlertMessage(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert
                severity="error"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Error while conceding: {error.message}
              </Alert>
            </div>
          );
        },
      }
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Debate Details</h1>
      {debate && (
        <div>
          {alertMessage}
          <h2>Topic: {debate.topic}</h2>
          <p>Status: {debate.status}</p>
          <p>Creator: {debate.creator.name}</p>
          <p>Total bank: {debate.bankRoll} </p>
          <h3>Participants:</h3>
          <ul>
            {debate.debaters.map((debater) => (
              <li key={debater._id}>{debater.name}</li>
            ))}
          </ul>
          <button onClick={handleConcede}>Concede</button>
          <br />
          <br />
          <button
            onClick={() =>
              alert("Adding observer functionality is under development")
            }
          >
            Add Observer
          </button>
        </div>
      )}
    </div>
  );
}

export default DebateParticipantDetails;
