import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { constants } from "./../shared/constants";
import styles from "./HomePage.module.css";
import Icon from "../shared/ui/Icon";
import HomeIcon from "./../shared/static/icons/home-icon.svg";
import HomeIcon2 from "./../shared/static/icons/home-icon2.svg";
import HomeIcon3 from "./../shared/static/icons/home-icon3.svg";
import HomeIcon4 from "./../shared/static/icons/home-icon4.svg";
import HomeIcon5 from "./../shared/static/icons/home-icon5.svg";
import ButtonMobile from "../shared/ui/Button";

import { useDebatesActive, useMe, useJoinDebate } from "../hooks";

import { tokenService } from "../services/tokenService";

function HomePage() {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: constants.query });

  const handleRegisterClick = () => {
    navigate("/client-register");
  };

  return isDesktop ? (
    <div>
      <Typography variant="h4" gutterBottom>
        Home Page
      </Typography>
      <Typography paragraph>
        Arguing with a friend and want to kick their butt for real money?
      </Typography>
      <Typography paragraph>Place bets.</Typography>
      <Typography paragraph>Take the bankroll.</Typography>
      <Typography paragraph>You can top up your balance with USDT.</Typography>
      <Typography paragraph>
        Create debates without money - just leave the stake at zero.
      </Typography>
      <Typography paragraph>
        Invite judges and fans, each participant role has its own chat to
        express themselves.
      </Typography>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleRegisterClick}
      >
        Quick Registration
      </Button>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentBox}>
          <Typography variant="h6" className={styles.title}>
            Arguing with a friend?
          </Typography>
          <Typography variant="body1" className={styles.subtitle}>
            Want to kick their butt for real money?
          </Typography>
          <div className={styles.info}>
            <Icon icon={HomeIcon} width={36} height={36} borderRadius={5} />
            <Typography variant="body2" align="left">
              Place bets
            </Typography>
          </div>
          <div className={styles.info}>
            <Icon icon={HomeIcon2} width={36} height={36} borderRadius={5} />
            <Typography variant="body2" align="left">
              Take the bankroll
            </Typography>
          </div>
          <div className={styles.info}>
            <Icon icon={HomeIcon3} width={36} height={36} borderRadius={5} />
            <Typography variant="body2" align="left">
              You can top up your balance with USDT
            </Typography>
          </div>
          <div className={styles.info}>
            <Icon icon={HomeIcon4} width={36} height={36} borderRadius={5} />
            <Typography variant="body2" align="left">
              Create debates for fun, just leave the stake empty
            </Typography>
          </div>
          <div className={styles.info}>
            <Icon icon={HomeIcon5} width={36} height={36} borderRadius={5} />
            <Typography variant="body2" align="left">
              Invite judges and fans
            </Typography>
          </div>
          <ButtonMobile
            title="Log in"
            onClick={() => {}}
            variant="light"
            style={{ marginBottom: 10 }}
          />
          <ButtonMobile title="Register" onClick={() => {}} />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
