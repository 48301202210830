import { useMutation } from "react-query";
import { apiInstance } from "../api/instance";

const completedJudges = async ({ winnerId, debateId, status }) => {
  let url = `/judges/${debateId}/completed-judge`;
  let data = { winnerId };

  if (status === "disputed") {
    url = `/judges/${debateId}/dispute-judge`;
  }

  const response = await apiInstance.post(url, data);

  return response.data;
};

export const useCompletedJudges = () => {
  return useMutation(completedJudges, {
    onError: (error) => {
      console.error("Error completed judge:", error.message);
    },
  });
};
