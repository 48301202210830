import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { constants } from "./../shared/constants";
import styles from "./HomePage.module.css";

import { useActiveDebates, useJudgeAcceptInvite, useMe } from "../hooks";

function NotificationPage() {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: constants.query });

  const { data: dataMe, isLoading: meIsLoading, isError: meIsError } = useMe();

  const {
    data: activeDebates,
    isLoading: activeDebatesIsLoading,
    isError: activeDebatesIsError,
  } = useActiveDebates();

  const {
    mutate: acceptInvite,
    isLoading: acceptInviteIsLoading,
    isError: acceptInviteIsError,
  } = useJudgeAcceptInvite();

  const handleAcceptInvite = (debateId) => {
    acceptInvite(
      { debateId },
      {
        onSuccess: () => {
          // alert("You have successfully accept inveit.");
          setTimeout(() => {
            navigate(`/debates/${debateId}`);
          }, 3000);
        },
      }
    );
  };

  const handleDetailDebateAdmin = (debateId) => {
    navigate(`/debates/${debateId}`);
  };

  useEffect(() => {
    if (dataMe?.role === "basic") {
      navigate("/");
    }
  }, [dataMe, navigate]);

  const buttonDisabled = useMemo(() => {
    return (debate) => {
      if (dataMe?.role === "judge") {
        if (debate.status === "active" && debate.judge) {
          return true;
        }
        if (debate.status === "disputed" && debate.disputJudge.length >= 3) {
          return true;
        }
      }
      return false;
    };
  }, [dataMe?.role]);

  const isUserInDebate = (debate) => {
    return (
      debate.judge === dataMe?._id ||
      debate.disputJudge.some((judge) => judge._id === dataMe?._id)
    );
  };

  if (activeDebatesIsLoading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (activeDebatesIsError) {
    return <Typography variant="h6">Error fetching data</Typography>;
  }

  return isDesktop ? (
    <div style={{ marginInline: 16 }}>
      <Typography variant="h4" gutterBottom>
        Active Debates
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Topic</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Debaters</TableCell>
              <TableCell>Creator</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeDebates.map((debate) => (
              <TableRow key={debate._id}>
                <TableCell>{debate.topic}</TableCell>
                <TableCell>{debate.status}</TableCell>
                <TableCell>{debate.debaters.length}</TableCell>
                <TableCell>{debate.creator.name}</TableCell>
                <TableCell>
                  {dataMe?.role === "judge" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAcceptInvite(debate._id)}
                      disabled={buttonDisabled(debate)}
                    >
                      Accept Invite
                    </Button>
                  )}
                  {dataMe?.role === "judge" && isUserInDebate(debate) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDetailDebateAdmin(debate._id)}
                    >
                      Join
                    </Button>
                  )}
                  {dataMe?.role === "admin" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDetailDebateAdmin(debate._id)}
                    >
                      Dispute Overview
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentBox}>
          <Typography variant="h6" className={styles.title}>
            Active Debates
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Topic</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Debaters</TableCell>
                  <TableCell>Creator</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeDebates.map((debate) => (
                  <TableRow key={debate._id}>
                    <TableCell>{debate.topic}</TableCell>
                    <TableCell>{debate.status}</TableCell>
                    <TableCell>{debate.debaters.length}</TableCell>
                    <TableCell>{debate.creator.name}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/debates/${debate._id}`)}
                      >
                        Accept Invite
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default NotificationPage;
