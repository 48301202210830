import { Typography, CircularProgress } from "@mui/material";

import { useHistoryAll } from "../hooks";

import { TableDebate } from "../modules/disputes/components/TableDebate";

function HistoryAll() {
  const { data, isLoading, error } = useHistoryAll();

  const debates = data?.debates || [];

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  return (
    <div>
      <h1>History of all debates on the site</h1>

      <p>You can view the most engaging debates and vote.</p>
      <p>Filter by the most expensive debates.</p>
      <p>Filter by the most popular debates.</p>

      <TableDebate data={debates} />

      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
}

export default HistoryAll;
