import { useQuery } from "react-query";
import { apiInstance } from "../../api/instance";

const allJudges = async () => {
  const response = await apiInstance.get(`/admin/judges`);

  return response.data;
};

export const useAllJudge = () => {
  return useQuery("allJudges", allJudges, {
    retry: 1,
    onError: (error) => console.error("Failed to all judges:", error),
  });
};
