import { useQuery } from "react-query";
import { apiInstance } from "../../api/instance";

const activeDebates = async () => {
  const response = await apiInstance.get(`/judges/active-debates`);

  return response.data;
}

export const useJudgeActiveDebates = () => {
  return useQuery('judgeActiveDebates', activeDebates, {
    retry: 1,
    onError: (error) => console.error("Failed to fetch active debates:", error),
  });
}