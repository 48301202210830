import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { apiInstance } from "../api/instance";
import { tokenService } from "../services/tokenService";

const createDebate = async ({ topic, stakes, lifetime }) => {
  const response = await apiInstance.post(`/debates/create`, {
    topic,
    stakes: parseInt(stakes, 10),
    lifetime,
  });

  return response.data;
};

export const useCreateDebate = () => {
  const navigate = useNavigate();

  return useMutation(createDebate, {
    onSuccess: (data) => {
      navigate(`/debates/${data.debateId}`);
    },
    onError: (error) => {
      console.error("Failed to join the debate:", error);
    },
  });
};
