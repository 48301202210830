import { useMutation } from "react-query";
import { apiInstance } from "../api/instance";

const concede = async ({ debateId }) => {
  const response = await apiInstance.post(`/debates/${debateId}/concede`);

  return response.data;
};

export const useConcede = () => {
  return useMutation(concede, {
    onError: (error) => {
      console.error("Failed to concede:", error);
    },
  });
};
