import { useMutation } from "react-query";
import { apiInstance } from "../api/instance";

const generateInviteLink = async ({ userId, debateId }) => {
  const response = await apiInstance.post(`/debates/${debateId}/invite`, {
    userId,
  });

  return response.data;
};

export const useGenerateInviteLink = () => {
  return useMutation(generateInviteLink, {
    onError: (error) => {
      console.error("Failed to generate invite link:", error);
    },
  });
};
