import { useMutation, useQueryClient  } from "react-query";
import { apiInstance } from "../../api/instance";

const unassignJudge = async ({ debateId, judgeId }) => {
  const response = await apiInstance.post(`/admin/${debateId}/unassing-judge`, {
    judgeId,
  });
  return response.data;
};

export const useUnassignJudge = () => {
  const queryClient = useQueryClient();

  return useMutation(unassignJudge, {
    onSuccess: (_, { debateId }) => {
      queryClient.refetchQueries(["debateDetails", debateId]);
    },
    onError: (error) => {
      console.error("Failed unassign judge:", error);
    },
  });
};
