import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Typography,
  Paper,
  Link,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";
import { tokenService } from "./../../../services/tokenService";
import { constants } from "../../../shared/constants";
import { useMediaQuery } from "react-responsive";
import ArrowIcon from "./../../../shared/static/icons/arrow.svg";
import { useMe } from "../../../hooks/useMe";

import { useJudgeActiveDebates } from "../../../hooks";

function UserDebates() {
  const navigate = useNavigate();

  const { data: me } = useMe();
  const {
    data: judgeDebates,
    error: errorJudgeDebates,
    isLoading: isLoadingJudgeDebates,
  } = useJudgeActiveDebates();

  const [debates, setDebates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const isDesktop = useMediaQuery({ query: constants.query });

  useEffect(() => {
    const fetchDebates = async () => {
      let token = tokenService.getToken();

      if (!token) {
        const userData = tokenService.getUserData();
        token = userData?.token;
      }

      if (!token) {
        setError("Token not found. Please log in.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          "https://polemic.club/api/debates/creator",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setDebates(response.data);

        setLoading(false);
      } catch (err) {
        setError("Error fetching debates: " + err.message);
        setLoading(false);
      }
    };

    fetchDebates();
  }, []);

  const handleInviteLink = async (debateId) => {
    let token = tokenService.getToken();

    if (!token) {
      const userData = tokenService.getUserData();
      token = userData?.token;
    }

    if (!token) {
      setError("Token not found. Please log in.");
      return;
    }

    try {
      if (
        me?._id ===
        debates?.find((item) => item?._id === debateId)?.creator?._id
      ) {
        navigate(`/debates/${debateId}`);
        return;
      }

      const response = await axios.post(
        `https://polemic.club/api/judges/${debateId}/accept-invit`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response?.status === 200) {
        navigate(`/debates/${debateId}`);
      }

      console.log("Invite link:", response.data.inviteLink);
    } catch (err) {
      setError("Error generating invite link: " + err.message);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return isDesktop ? (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Active debates created by me:
      </Typography>
      <Box
        sx={{
          display: "flex",
          cursor: "pointer",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {debates.length > 0 ? (
          debates.map((debate) => (
            <Paper key={debate._id} elevation={3} sx={{ padding: 2 }}>
              <div onClick={() => handleInviteLink(debate._id)}>
                {/* <Link
                  component={RouterLink}
                  to={`/debates/${debate._id}`}
                  underline="none"
                > */}
                <Typography variant="h6" color="primary">
                  Topic: {debate.topic}
                </Typography>
                <Typography variant="body1">Status: {debate.status}</Typography>
                {/* </Link> */}
              </div>
            </Paper>
          ))
        ) : (
          <Typography>You haven't created any debates yet.</Typography>
        )}
      </Box>
    </Container>
  ) : (
    <Container maxWidth="md">
      {debates.length > 0 ? (
        debates.map((debate) => (
          <Paper
            key={debate._id}
            elevation={3}
            sx={{
              padding: 2,
              borderRadius: "10px",
              mb: 2,
              background: "#EEF6FC",
              boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
              -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
              5px -5px 10px 0px rgba(204, 211, 216, 0.2),
              -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
            }}
          >
            <Link
              component={RouterLink}
              to={`/debates/${debate._id}`}
              underline="none"
            >
              <Typography variant="h6" color="#000000" align="left">
                {debate.topic}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    background: "rgba(63, 197, 0, 1)",
                    borderRadius: "30px",
                    padding: "5px 10px",
                  }}
                >
                  <Typography color="#ffffff">{debate.status}</Typography>
                </Box>
                <img src={ArrowIcon} alt="icon" />
              </Box>
            </Link>
          </Paper>
        ))
      ) : (
        <Typography>You haven't created any debates yet.</Typography>
      )}
    </Container>
  );
}

export default UserDebates;
