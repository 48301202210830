import React from 'react';
import styles from './Input.module.css';

const Input = ({
  label,
  type = 'text',
  value,
  onChange,
  name,
  placeholder,
  options = {},
  ...props
}) => {
  if (type === 'select') {
    return (
      <div className={styles.container}>
        <label htmlFor={name}>{label}</label>
        <div>
          <select name={name} {...props}>
            {options.map((option) => (
              <option value={option.value}>{option.name}</option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <label htmlFor={name}>{label}</label>
      <div>
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
    </div>
  );
};

export default Input;
