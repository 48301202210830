import {
  List,
  ListItem,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";

export const TableDebate = ({ me, data = [], callback, isDispute }) => {

  return (
    <List>
      {Array.isArray(data) && data.length > 0 ? (
        data.map((debate) => (
          <ListItem key={debate._id} divider>
            <Card variant="outlined" sx={{ width: "100%" }}>
              <CardContent>
                <Typography variant="h5">{debate.topic}</Typography>
                <Typography variant="body1">
                  <strong>Status:</strong> {debate.status}
                </Typography>
                <Typography variant="body1">
                  <strong>Creator:</strong> {debate.creator.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Winner:</strong>{" "}
                  {debate.winner ? debate.winner.name : "Winner not determined"}
                </Typography>
                <Typography variant="body1">
                  <strong>Bank:</strong> {debate.bankRoll}
                </Typography>
                <Typography variant="body1">
                  <strong>Stake:</strong> {debate.stakes}
                </Typography>

                {debate.loser === me?._id && isDispute && (
                  <Button
                    onClick={() => callback(debate?._id)}
                    variant="contained"
                  >
                    Dispute
                  </Button>
                )}
              </CardContent>
            </Card>
          </ListItem>
        ))
      ) : (
        <Typography>No debates available.</Typography>
      )}
    </List>
  );
};
