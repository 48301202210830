import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DebateChat from "./../modules/disputes/components/DebateChat";
import {
  Typography,
  Button,
  CircularProgress,
  Container,
  Box,
  List,
  ListItem,
  TextField,
  Autocomplete,
  Alert,
} from "@mui/material";

import { useMe } from "../hooks/useMe";
import {
  useConcede,
  useAllJudge,
  useDebateDetail,
  useAssignJudge,
  useCompletedJudges,
  useAssignJudgeDisput,
  useInviteUserToJudge,
  useGenerateInviteLink,
} from "../hooks";

import { JudgesTable } from "../modules/disputes/components/JudgesTable";
import { JudgeCard } from "../modules/disputes/components/JudgeCard";

function DebateDetails() {
  const { debateId } = useParams();

  const { data: user = {}, isLoading: isLoadingUser } = useMe();
  const { _id: userId, role } = user;

  const [error, setError] = useState("");
  const [inviteLink, setInviteLink] = useState("");
  const [winner, setWinner] = useState(null);

  const [selectUser, setSelectUser] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [onJudgeSelect, setOnJudgeSelect] = useState(null);

  const {
    data: allJudges,
    isLoading: allJudgesIsLoading,
    isError: allJudgesIsError,
  } = useAllJudge();

  const {
    data: debateDetails,
    isLoading: detailsIsLoading,
    isError: detailsIsError,
  } = useDebateDetail(debateId);

  const {
    mutate: generateInviteLink,
    isLoading: generateIsLoading,
    isError: generateIsError,
  } = useGenerateInviteLink();

  const {
    mutate: concede,
    isLoading: concedeIsLoading,
    isError: concedeIsError,
  } = useConcede();

  const {
    mutate: completedJudges,
    isLoading: completedIsLoading,
    isError: completedIsError,
  } = useCompletedJudges();

  const {
    mutate: inviteUserToJudge,
    isLoading: inviteIsLoading,
    isError: inviteIsError,
  } = useInviteUserToJudge();

  const {
    mutate: assignJudgeDisput,
    isLoading: assignIsLoading,
    isError: assignIsError,
  } = useAssignJudgeDisput();

  const {
    mutate: assignJudge,
    isLoading: assignDebateIsLoading,
    isError: assignDebateIsError,
  } = useAssignJudge();

  const navigate = useNavigate();

  const isInviteButtonDisabled = useMemo(() => {
    if (role === "admin") {
      return !onJudgeSelect || debateDetails?.disputJudge.length === 3;
    } else {
      return !!debateDetails?.judge;
    }
  }, [role, onJudgeSelect, debateDetails]);

  const inviteJudgeButton = useMemo(() => {
    if (debateDetails?.status === "active") {
      if (role === "admin") {
        if (debateDetails.judge) {
          return "Change judge";
        } else {
          return "Add judge";
        }
      } else if (role === "basic") {
        return "Invite judge";
      }
    } else if (debateDetails?.status === "disputed") {
      if (role === "admin") {
        return "Add dispute judge";
      }
    }
    return null;
  }, [role, debateDetails]);

  useEffect(() => {
    if (userId && debateId) {
      generateInviteLink(
        { userId, debateId },
        {
          onSuccess: (data) => {
            console.log("data generateInviteLink", data);
            setInviteLink(data);
          },
          onError: (error) => {
            console.error("Ошибка при генерации ссылки:", error);
          },
        }
      );
    }
  }, [userId, debateId, generateInviteLink]);

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);
    alert("Invite link copied to clipboard!");
  };

  const handleConcede = async () => {
    concede(
      { debateId },
      {
        onSuccess: (response) => {
          setAlertMessage(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert
                severity="success"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                You have successfully conceded. Winner: {response.winner}
              </Alert>
            </div>
          );
          setTimeout(() => {
            navigate("/");
          }, 3000);
        },
        onError: (error) => {
          setAlertMessage(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert
                severity="error"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Error while conceding: {error.message}
              </Alert>
            </div>
          );
        },
      }
    );
  };

  const handleWon = async (winnerId, status) => {
    completedJudges(
      { winnerId, debateId, status },
      {
        onSuccess: (response) => {
          if (status === "active") {
            navigate("/");
          } else if (status === "disputed") {
            const { data, message } = response;
            if (data?.judgeVotes && data?.judgeVotes.length === 3) {
              navigate("/");
            } else {
              setAlertMessage(
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Alert
                    severity="success"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {`Vote successfully cast! ${message}`}
                  </Alert>
                </div>
              );
            }
          }
        },
        onError: (error) => {
          setError("Error completed disput: " + error.message);
        },
      }
    );
  };

  const handleAssignJudge = async () => {
    if (!onJudgeSelect) {
      setError("No judge selected.");
      return;
    }

    const judgeAssignment =
      debateDetails?.status === "disputed" ? assignJudgeDisput : assignJudge;

    judgeAssignment(
      { debateId, judgeId: onJudgeSelect.id },
      {
        onSuccess: (response) => {
          setOnJudgeSelect(null);
          console.log("Judge assigned successfully:", response);
        },
        onError: (error) => {
          setError("Error assigning judge: " + error.message);
        },
      }
    );
  };

  const handleInviteUserToJudge = () => {
    if (role === "admin") {
      handleAssignJudge();
    } else {
      inviteUserToJudge({ debateId });
    }
  };

  if (detailsIsLoading) return <CircularProgress />;

  const hasJudgeVoted = debateDetails?.judgeVotes.some(
    (vote) => vote?.judgeId?._id === userId
  );

  const isCreator = debateDetails && debateDetails.creator._id === userId;
  const isLoadingAddJudge = assignIsLoading || assignDebateIsLoading;
  const joinedJudgesCount = debateDetails?.disputJudge?.length || 0;
  const judgeVotesCount = debateDetails?.judgeVotes?.length || 0;

  return (
    <Container>
      <Typography variant="h4">Debate Details</Typography>
      {debateDetails && (
        <Box>
          <Typography variant="h5">Topic: {debateDetails.topic}</Typography>
          <Typography>Status: {debateDetails.status}</Typography>
          {debateDetails.status === "completed" && winner && (
            <Typography>Winner: {winner.name}</Typography>
          )}
          <Typography>Creator: {debateDetails.creator.name}</Typography>
          <Typography>Total Bank: {debateDetails.bankRoll}</Typography>
          <Typography variant="h6">Participants:</Typography>

          {debateDetails.status === "disputed" && (
            <Box>
              <Typography variant="h6">
                Judges Joined: {joinedJudgesCount}/3
              </Typography>
              <Typography variant="h6">
                Judges Voted: {judgeVotesCount}/3
              </Typography>
            </Box>
          )}

          {alertMessage}

          <List>
            {debateDetails?.debaters.map((debater) => (
              <ListItem key={debater._id}>
                <Box
                  style={
                    debater?._id === selectUser?._id
                      ? {
                          border: "1px solid blue",
                          backgroundColor: "blue",
                          color: "white",
                          cursor:
                            role === "judge" &&
                            debateDetails?.status !== "completed" &&
                            !hasJudgeVoted
                              ? "pointer"
                              : "default",
                        }
                      : {
                          cursor:
                            role === "judge" &&
                            debateDetails?.status !== "completed" &&
                            !hasJudgeVoted
                              ? "pointer"
                              : "default",
                        }
                  }
                  border={1}
                  borderRadius={2}
                  p={2}
                  mb={1}
                  width="30%"
                  onClick={() => {
                    if (
                      role === "judge" &&
                      debateDetails?.status !== "completed" &&
                      !hasJudgeVoted
                    ) {
                      setSelectUser(debater);
                    }
                  }}
                >
                  <Typography variant="body1">
                    {debater.name} — {debater.email} — Rank: {debater.rank}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
          {debateDetails.status === "active" && role === "basic" && (
            <Button
              onClick={handleConcede}
              disabled={debateDetails.debaters.length === 1}
              variant="contained"
              color="error"
            >
              {concedeIsLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Concede"
              )}
            </Button>
          )}

          {role !== "judge" && (
            <Button
              onClick={handleInviteUserToJudge}
              disabled={isInviteButtonDisabled}
              variant="contained"
              color="success"
            >
              {isLoadingAddJudge ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                `${inviteJudgeButton}`
              )}
            </Button>
          )}

          {role === "judge" &&
            !debateDetails?.debaters?.some(
              (debater) => debater._id === userId?._id
            ) && (
              <Button
                onClick={() =>
                  debateDetails.status === "active"
                    ? handleWon(selectUser?._id)
                    : handleWon(selectUser?._id, "disputed")
                }
                disabled={
                  !selectUser ||
                  hasJudgeVoted ||
                  completedIsLoading ||
                  debateDetails?.judgeVotes.some(
                    (vote) => vote.judgeId === userId
                  )
                }
                variant="contained"
                color="success"
              >
                {completedIsLoading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Won"
                )}
              </Button>
            )}

          {debateDetails.judge === userId && (
            <Button onClick={handleConcede} variant="contained">
              Completed
            </Button>
          )}

          {role === "admin" && (
            <Box sx={{ marginY: 2 }}>
              <Autocomplete
                value={onJudgeSelect}
                options={allJudges.responseData || []}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => setOnJudgeSelect(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Judge"
                    variant="outlined"
                    fullWidth
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={allJudgesIsLoading}
              />
            </Box>
          )}

          {debateDetails && debateDetails.disputJudge.length !== 0 && (
            <Box sx={{ marginY: 2 }}>
              <JudgesTable
                debateDetails={debateDetails}
                debateId={debateId}
                userRole={role}
                setAlert={setAlertMessage}
              />
            </Box>
          )}

          {debateDetails?.status === "active" && debateDetails?.judge && (
            <JudgeCard
              judge={debateDetails?.judge}
              debateId={debateId}
              role={role}
            />
          )}

          <DebateChat debateId={debateId} userId={userId} />
          {isCreator && inviteLink && (
            <Box>
              <TextField
                label="Invite Link"
                value={inviteLink?.inviteLink}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <Button onClick={handleCopyInviteLink} variant="contained">
                Copy Invite Link
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
}

export default DebateDetails;
