import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";

import { useAllDebates, useAllUsers } from "../../../hooks";

// Sample data
const sampleDebates = [
  {
    title: "Debate 1",
    participants: "Alice, Bob",
    judge: "Judge 1",
    stake: "$500",
    status: "Ongoing",
  },
  {
    title: "Debate 2",
    participants: "Charlie, Dave",
    judge: "Judge 2",
    stake: "$300",
    status: "Completed",
  },
];

export const AdminDashboard = () => {
  const page = 1;
  const limit = 10;

  const {
    data: usersData,
    error: usersError,
    isLoading: usersIsloading,
  } = useAllUsers();

  const { users, totalUsers } = usersData || {
    users: [],
    totalUsers: 0,
  };

  const { data, error, isLoading } = useAllDebates(page, limit);
  const { debates, totalDebates } = data?.data || {
    debates: [],
    totalDebates: 0,
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert severity="error">Failed to fetch debates: {error.message}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box display="flex" gap={2} mb={3}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              Users
            </Typography>
            <Typography variant="h4" component="div">
              {totalUsers}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              Debates
            </Typography>
            <Typography variant="h4" component="div">
              {totalDebates}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box display="flex" flexDirection="column" mb={3}>
        <Typography variant="h6" component="div" mb={2}>
          Latest Debates
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Judge</TableCell>
                <TableCell>Stake</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {debates.map((debate) => (
                <TableRow key={debate._id}>
                  <TableCell>{debate.topic}</TableCell>
                  <TableCell>{debate.judge}</TableCell>
                  <TableCell>{debate.stakes}</TableCell>
                  <TableCell>{debate.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
