import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { tokenService } from "./../../../services/tokenService";
import { useMediaQuery } from "react-responsive";
import { constants } from "../../../shared/constants";
import ShareIcon from "./../../../shared/static/icons/share.svg";
import ButtonMobile from "./../../../shared/ui/Button";
import Input from "../../../shared/ui/Input";

import { useCreateDebate } from "../../../hooks";

function CreateDebate() {
  const [topic, setTopic] = useState("");
  const [stakes, setStakes] = useState("");
  const [userId, setUserId] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [lifetime, setLifetime] = useState("");
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: constants.query });

  const { mutate: createDebate, isLoading, isError } = useCreateDebate();

  useEffect(() => {
    let token = tokenService.getToken();

    if (!token) {
      const userData = tokenService.getUserData();
      token = userData?.token;
    }

    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserId(decoded._id);
      } catch (error) {
        setError("Error decoding token: " + error.message);
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!topic || !stakes || !lifetime) {
      setError("All fields are required.");
      return;
    }

    createDebate(
      { topic, stakes, lifetime },
      {
        onSuccess: (data) => {
          setSuccessMessage(
            `Debate created successfully with ID: ${data.debateId}`
          );
          setError("");
        },
        onError: (error) => {
          setError("Failed to create debate: " + error.message);
          setSuccessMessage("");
        },
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          fontWeight={isDesktop ? "normal" : "bold"}
          color={isDesktop ? "#000000" : "#31383D"}
          sx={{ alignSelf: isDesktop ? "center" : "flex-start" }}
        >
          {isDesktop ? "Создай новый спор:" : "Новый спор"}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={
            isDesktop
              ? { mt: 1 }
              : {
                  mt: 1,
                  padding: "20px",
                  borderRadius: 5,
                  boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
                    -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
                    5px -5px 10px 0px rgba(204, 211, 216, 0.2),
                    -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
                  backgroundColor: "#EEF6FC",
                  width: "100%",
                }
          }
        >
          {isDesktop ? (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="topic"
              label="Тема спора"
              name="topic"
              autoComplete="topic"
              autoFocus
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
            />
          ) : (
            <Input
              label="Тема спора"
              name="topic"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
            />
          )}
          {isDesktop ? (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="stakes"
              label="Ставка USDT"
              type="number"
              id="stakes"
              value={stakes}
              onChange={(e) => setStakes(e.target.value)}
              inputProps={{ min: 0 }}
            />
          ) : (
            <Input
              label="Ставка USDT"
              name="stakes"
              value={stakes}
              onChange={(e) => setStakes(e.target.value)}
              inputProps={{ min: 0 }}
            />
          )}
          {isDesktop ? (
            <p>Ставка 0 для спора на интерес*</p>
          ) : (
            <Typography variant="body2" color="#6E7D8A">
              Ставка 0 для спора на интерес*
            </Typography>
          )}

          {/* New Select field for debate lifetime */}

          {isDesktop ? (
            <FormControl fullWidth margin="normal">
              <InputLabel id="lifetime-label">Время жизни спора</InputLabel>
              <Select
                labelId="lifetime-label"
                id="lifetime"
                value={lifetime}
                label="Время жизни спора"
                onChange={(e) => setLifetime(e.target.value)}
                required
              >
                <MenuItem value="1h">1 час</MenuItem>
                <MenuItem value="1d">1 день</MenuItem>
                <MenuItem value="1w">1 неделя</MenuItem>
                <MenuItem value="1m">1 месяц</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Input
              type="select"
              name="lifetime"
              value={lifetime}
              onChange={(e) => setLifetime(e.target.value)}
              options={[
                { name: "1 час", value: "1h" },
                { name: "1 день", value: "1d" },
                { name: "1 неделя", value: "1w" },
                { name: "1 месяц", value: "1m" },
              ]}
            />
          )}

          {isDesktop ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Создай спор
            </Button>
          ) : (
            <ButtonMobile
              title="Создать спор"
              type="submit"
              style={{ marginTop: 16 }}
            />
          )}
          {error && <Typography color="error">{error}</Typography>}
          {successMessage && (
            <Typography color="primary">{successMessage}</Typography>
          )}
          {isDesktop && (
            <p>В споре ты найдешь ссылку чтобы пригласить оппонента</p>
          )}
        </Box>

        {!isDesktop && (
          <Box
            sx={{
              mt: 3,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mb: 3,
              gap: 1,
            }}
          >
            <img src={ShareIcon} alt="icon" />
            <Typography variant="body2" color="#6E7D8A">
              В споре ты найдешь ссылку, чтобы пригласить оппонента
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default CreateDebate;
