import { useQuery } from "react-query";
import { apiInstance } from "../api/instance";

const debatesHistory = async (stake) => {
  const url =
    `https://polemic.club/api/debates/debater?status=completed` +
    (stake ? `&minStakes=${stake}` : "");

  const response = await apiInstance.get(url);

  return response.data;
};

export const useDebatesHistory = (stake) => {
  return useQuery(["completedDebates", stake], () => debatesHistory(stake), {
    retry: 1,
    onError: (error) => {
      console.error("Failed to load completed debates:", error.message);
    },
  });
};
