import { useQuery } from "react-query";
import { apiInstance } from "../api/instance";

const activeDebates = async () => {
  const response = await apiInstance.get(`/debates/active`);
  return response.data;
};

export const useActiveDebates = () => {
  return useQuery("activeDebates", activeDebates, {
    retry: 1,
  });
};
