import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styles from "./HeaderPanel.module.css";

import { useMe } from "../../hooks";

function HeaderPanel() {
  const { data: user = {} } = useMe();

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (user.role) {
      setUserRole(user.role);
    }
  }, [user]);

  return (
    <div>
      <nav>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to="/">
              Home Page
            </NavLink>
          </li>
          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to="/new-debates">
              Create Debate
            </NavLink>
          </li>

          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to="/user-active-debates">
              My Active Debates
            </NavLink>
          </li>

          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to="/user-page">
              My Profile
            </NavLink>
          </li>

          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to="/my-history">
              My Debate History
            </NavLink>
          </li>

          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to="/history-all">
              Others' Debate History
            </NavLink>
          </li>
          {userRole === null ||
            (userRole !== "basic" && (
              <li className={styles.navItem}>
                <NavLink className={styles.navLink} to="/notification">
                  Notification
                </NavLink>
              </li>
            ))}
        </ul>
      </nav>
      <br></br>
      <br></br>
    </div>
  );
}

export default HeaderPanel;
