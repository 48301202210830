import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useUnassignJudgeDisput } from "../../../hooks";

export const JudgesTable = ({
  debateDetails,
  debateId,
  userRole,
  setAlert,
}) => {
  const {
    mutate: unassignJudgeDisput,
    isLoading: unassignIsLoading,
    error,
  } = useUnassignJudgeDisput();

  const [loadingJudgeId, setLoadingJudgeId] = useState(null);

  const findVotedDebater = (voteId) => {
    const votedDebater = debateDetails.debaters.find(
      (debater) => debater._id === voteId
    );
    return votedDebater ? votedDebater.name : "Unknown";
  };

  const handleRemoveJudge = (judgeId) => {
    if (debateDetails && debateId && judgeId) {
      setLoadingJudgeId(judgeId);
      unassignJudgeDisput(
        { debateId, judgeId },
        {
          onSettled: () => {
            setLoadingJudgeId(null);
          },
          onError: (error) => {
            setAlert(
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Alert
                  severity="error"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {error}
                </Alert>
              </div>
            );
          },
        }
      );
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Vote</TableCell>
            {userRole === "admin" && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {debateDetails.disputJudge.map((judge) => {
            const judgeVote = debateDetails.judgeVotes.find(
              (vote) => vote.judgeId && vote.judgeId._id === judge.userId
            );
            const votedDebaterName = judgeVote
              ? findVotedDebater(judgeVote.vote._id)
              : "Not Voted Yet";

            return (
              <TableRow key={judge._id}>
                <TableCell>{judge.name}</TableCell>
                <TableCell>{judge.role}</TableCell>
                <TableCell>{votedDebaterName}</TableCell>{" "}
                {userRole === "admin" && (
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemoveJudge(judge._id)}
                      disabled={loadingJudgeId === judge._id || !!judgeVote}
                    >
                      {loadingJudgeId === judge._id ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Remove Judge"
                      )}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
