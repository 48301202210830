import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { useUnassignJudge } from "../../../hooks";

export const JudgeCard = ({ judge, debateId, role }) => {
  const { mutate: unassignJudge, isLoading: unassignIsLoading } =
  useUnassignJudge();

  const handleRemoveJudge = (judgeId) => {
    if (debateId && judgeId) {
      unassignJudge({ debateId, judgeId });
    }
  };

  return (
    <Box
      sx={{
        mb: 2,
        mt: 2,
        width: "100%",
      }}
    >
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          width: "100%",
        }}
      >
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Typography variant="h6" component="div">
            {judge.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            ID: {judge._id}
          </Typography>
        </CardContent>
        {role === "admin" && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleRemoveJudge(judge._id)}
            disabled={unassignIsLoading}
            sx={{ margin: 2 }}
          >
            {unassignIsLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Remove Judge"
            )}
          </Button>
        )}
      </Card>
    </Box>
  );
};
