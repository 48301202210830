import React, { useState, useEffect } from 'react';
import { Button, Typography, CircularProgress, Box } from '@mui/material';
import { walletService } from './../../../services/walletService';

function Wallet() {
  const [walletStatus, setWalletStatus] = useState('checking');
  const [walletAddress, setWalletAddress] = useState('');
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState('');
  const [walletInfo, setWalletInfo] = useState(null);

  useEffect(() => {
    checkWalletStatus();
  }, []);

  const checkWalletStatus = async () => {
    try {
      setWalletStatus('checking');
      const result = await walletService.checkWallet();
      if (result.exists) {
        setWalletStatus('exists');
        setWalletAddress(result.address);
        await fetchBalance();
      } else {
        setWalletStatus('not_exists');
      }
    } catch (error) {
      setError('Failed to check wallet status. Please try again.');
      setWalletStatus('error');
    }
  };

  const createWallet = async () => {
    try {
      setWalletStatus('creating');
      const result = await walletService.createWallet();
      setWalletInfo(result);
      setWalletStatus('exists');
      await fetchBalance();
    } catch (error) {
      console.error('Failed to create wallet:', error);
      setError(
        error.response?.data?.message ||
          'Failed to create wallet. Please try again.'
      );
      setWalletStatus('error');
    }
  };

  const fetchBalance = async () => {
    try {
      const result = await walletService.getBalance();
      if (!result.exists) {
        setWalletStatus('not_exists');
        setError(result.message);
      } else {
        setWalletInfo(result);
        setWalletStatus('exists');
        setBalance(result.balance);
      }
    } catch (error) {
      setError('Failed to fetch balance. Please try again.');
      setWalletStatus('error');
    }
  };

  const decryptPrivateKey = async () => {
    try {
      console.log('Starting decryptPrivateKey function');
      console.log('Wallet Info:', walletInfo);

      const result = await walletService.decryptPrivateKey();
      console.log('Private key:', result.message);
    } catch (error) {
      console.error('Failed to decrypt private key:', error);

      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }

      setError('Failed to decrypt private key. Please try again.');
    }
  };

  const renderWalletContent = () => {
    switch (walletStatus) {
      case 'checking':
        return <CircularProgress />;
      case 'not_exists':
        return (
          <Button variant='contained' color='primary' onClick={createWallet}>
            Create Wallet
          </Button>
        );
      case 'creating':
        return <CircularProgress />;
      case 'exists':
        return (
          <>
            <Typography variant='body1'>
              Wallet Address: {walletInfo?.address}
            </Typography>
            <Typography variant='body1'>
              Balance: {walletInfo?.balance} {walletInfo?.currency}
            </Typography>
            <Typography variant='body1'>
              Status: {walletInfo?.status}
            </Typography>
            <Button
              variant='contained'
              color='secondary'
              onClick={decryptPrivateKey}
            >
              Decrypt Private Key
            </Button>
          </>
        );
      case 'error':
        return <Typography color='error'>{error}</Typography>;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant='h4' gutterBottom>
        Your Wallet
      </Typography>
      {renderWalletContent()}
    </Box>
  );
}

export default Wallet;
