import React from 'react';
import { useParams } from 'react-router-dom';

import JudgePanel from '../modules/judging/components/JudgePanel'; 
import { useMediaQuery } from 'react-responsive';
import { constants } from '../shared/constants';
import styles from './UserPage.module.css';

const JudgePage = () => {
  const { makerId } = useParams();
  const isDesktop = useMediaQuery({ query: constants.query });

  return (
    <div className={styles.container}>
      {isDesktop ? (
        <div>
          <JudgePanel />
        </div>
      ) : (
        <div className={styles.content}>
          <JudgePanel />
        </div>
      )}
    </div>
  );
};

export default JudgePage;
