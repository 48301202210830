// src/services/walletService.js
import axios from "axios";
import { tokenService } from "./tokenService";

const API_URL = "http://localhost:3000/api/wallet"; // Обновлено с учетом глобального префикса '/api'

export const walletService = {
  async checkWallet() {
    let token = tokenService.getToken();

    if (!token) {
      const userData = tokenService.getUserData();
      token = userData?.token;
    }
    console.log("Token from tokenService:", token); // Логируем токен

    try {
      console.log("Sending request to:", `${API_URL}/check`); // Логируем URL запроса
      console.log("Request headers:", { Authorization: `Bearer ${token}` }); // Логируем заголовки

      const response = await axios.get(`${API_URL}/check`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Response status:", response.status); // Логируем статус ответа
      console.log("Response data:", response.data); // Логируем данные ответа

      return response.data;
    } catch (error) {
      console.error("Error checking wallet:", error);

      if (error.response) {
        // Запрос был сделан, и сервер ответил кодом состояния, который выходит за пределы 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // Запрос был сделан, но ответ не получен
        console.error("Error request:", error.request);
      } else {
        // Произошло что-то при настройке запроса, вызвавшее ошибку
        console.error("Error message:", error.message);
      }

      throw error;
    }
  },

  async createWallet() {
    const token = tokenService.getToken();
    try {
      const response = await axios.post(
        `${API_URL}/create`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Wallet created successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error creating wallet:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  async getBalance() {
    const token = tokenService.getToken();
    try {
      const response = await axios.get(`${API_URL}/balance`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error getting balance:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  async updateWalletStatus(status) {
    const token = tokenService.getToken();
    try {
      const response = await axios.put(
        `${API_URL}/status`,
        { status },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error updating wallet status:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  async updateBalance(amount) {
    const token = tokenService.getToken();
    try {
      const response = await axios.put(
        `${API_URL}/balance`,
        { amount },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error updating balance:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  async decryptPrivateKey() {
    const token = tokenService.getToken();
    try {
      const response = await axios.post(
        `${API_URL}/decrypt`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Response from server:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error decrypting private key:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
};
