import { useQuery } from "react-query";
import { apiInstance } from "../api/instance";

const fetchMe = async () => {
  const response = await apiInstance.get(`users/`);
  return response.data;
};

export const useMe = () => {
  return useQuery("me", fetchMe, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: 1,
    onError: (error) => console.error("Failed to fetch user data:", error),
  });
};
