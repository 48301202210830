import React, { useEffect, useRef } from "react";
import axios from "../axios";
import { tokenService } from "../services/tokenService";

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  const buttonRef = useRef(null);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    console.log("GoogleLoginButton: useEffect triggered");
    console.log("GoogleLoginButton: Client ID:", clientId);

    const loadGoogleScript = () => {
      console.log("GoogleLoginButton: Starting Google script loading");
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        console.log("GoogleLoginButton: Google script successfully loaded");
        initializeGoogleSignIn();
      };
      script.onerror = () => {
        console.error("GoogleLoginButton: Error loading Google script");
        onFailure("Error loading Google script");
      };
      document.body.appendChild(script);
    };

    const initializeGoogleSignIn = () => {
      console.log("GoogleLoginButton: Starting Google Sign-In initialization");
      if (window.google) {
        console.log("GoogleLoginButton: Google object found in window");
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: handleCredentialResponse,
        });
        console.log("GoogleLoginButton: Google Sign-In initialized");

        if (buttonRef.current) {
          console.log("GoogleLoginButton: Rendering Google button");
          window.google.accounts.id.renderButton(buttonRef.current, {
            theme: "outline",
            size: "large",
            text: "signin_with",
            width: buttonRef.current.offsetWidth,
          });
          console.log("GoogleLoginButton: Google button rendered");
        } else {
          console.error("GoogleLoginButton: Button ref not found");
        }
      } else {
        console.error("GoogleLoginButton: Google API not loaded");
        onFailure("Google API not loaded");
      }
    };

    loadGoogleScript();

    return () => {
      console.log("GoogleLoginButton: Component unmounted");
    };
  }, [clientId, onSuccess, onFailure]);

  const handleCredentialResponse = async (response) => {
    console.log("GoogleLoginButton: Google credential response received");
    console.log("Encoded JWT ID token: " + response.credential);
    try {
      const res = await axios.post(
        "https://polemic.club/api/users/auth/google",
        {
          token: response.credential,
        }
      );
      if (res.status === 200) {
        console.log(
          "GoogleLoginButton: User successfully authenticated:",
          res.data
        );
        tokenService.saveToken(res.data.token);

        onSuccess(res.data);
      } else {
        console.error("GoogleLoginButton: Authentication error:", res.data);
        onFailure(res.data);
      }
    } catch (error) {
      console.error("GoogleLoginButton: Error during authentication:", error);
      onFailure(error.message);
    }
  };

  console.log("GoogleLoginButton: Rendering component");
  return (
    <div
      ref={buttonRef}
      style={{
        width: "100%",
        maxWidth: "300px",
        height: "50px",
        border: "1px solid transparent",
      }}
    >
      {!window.google && "Loading Google button..."}
    </div>
  );
};

export default GoogleLoginButton;
