import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminRoute from "../modules/admin/components/AdminRoute";
import AdminPanel from "../pages/AdminPage";

import { AdminDashboard, Users, Debates } from "../modules/admin";

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route element={<AdminRoute />}>
        <Route index element={<AdminDashboard />} />
        <Route path="debates" element={<Debates />} />
        <Route path="debates/:id" element={<div>admin debates id</div>} />
        <Route path="users" element={<Users />} />
        <Route path="users/:id" element={<div>admin user id</div>} />
      </Route>
    </Routes>
  );
};
