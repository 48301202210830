import React from 'react';
import { useParams } from 'react-router-dom';

import CreateDebate from './../modules/disputes/components/CreateDebate';
import UserDetails from './../modules/users/components/UserDetails';
import { useMediaQuery } from 'react-responsive';
import { constants } from '../shared/constants';
import styles from './UserPage.module.css';
import MobilePanel from '../shared/ui/MobilePanel';

const UserPage = () => {
  const { makerId } = useParams();
  const isDesktop = useMediaQuery({ query: constants.query });

  return (
    <div className={styles.container}>
      {isDesktop && <h1></h1>}
      {isDesktop ? (
        <div>
          <UserDetails />
          <CreateDebate />
        </div>
      ) : (
        <div className={styles.content}>
          <UserDetails />
          <CreateDebate />
        </div>
      )}
      {!isDesktop && <MobilePanel />}
    </div>
  );
};

export default UserPage;
