import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Drawer, List, ListItem, ListItemText } from "@mui/material";

export const AdminLayout = ({ children }) => {
  return (
    <Box display="flex">
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
          },
        }}
      >
        <List>
          <ListItem button component="a" href="/admin/">
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component="a" href="/admin/users">
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button component="a" href="/admin/debates">
            <ListItemText primary="Debates" />
          </ListItem>
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2 }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
