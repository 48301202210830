import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Container,
  List,
  ListItem,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { constants } from "../shared/constants";
import styles from "./CompletedDebates.module.css";
import MobilePanel from "../shared/ui/MobilePanel";
import { tokenService } from "../services/tokenService";
import { TableDebate } from "../modules/disputes/components/TableDebate";

import { useMe, useDebatesHistory, useDisputeJudges } from "../hooks";

function CompletedDebates({ stake }) {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: constants.query });

  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");
  const [debates, setDebates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);

  const {
    data: me,
    isLoading: meIsLoading,
    isError: meIsError,
    error: meError,
  } = useMe();

  const {
    data: historyData,
    isLoading: historyIsLoading,
    isError: historyIsError,
  } = useDebatesHistory(stake);

  const {
    mutate: disputeJudges,
    isLoading: disputeJudgesIsLoading,
    isError: disputeJudgesIsError,
  } = useDisputeJudges();

  useEffect(() => {
    let token = tokenService.getToken();

    if (!token) {
      const userData = tokenService.getUserData();
      token = userData?.token;
    }

    if (!token) {
      setError("Authentication token is missing. Please log in again.");
      return;
    }
  }, []);

  const handleDisput = async (debateId) => {
    disputeJudges(
      { debateId },
      {
        onSuccess: () => {
          navigate(`/debates/${debateId}`);
        },
        onError: (error) => {
          setAlertMessage(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert
                severity="error"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Failed to create debate: + {error.message}
              </Alert>
            </div>
          );
        },
      }
    );
  };

  if (historyIsLoading) return <CircularProgress size={24} />;

  if (error)
    return (
      <Typography variant="h6" gutterBottom>
        {error}
      </Typography>
    );

  return isDesktop ? (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        My Debate History
      </Typography>
      {alertMessage}

      <TableDebate
        me={me}
        isDispute
        data={historyData}
        callback={handleDisput}
      />
    </Container>
  ) : (
    <div className={styles.container}>
      <div className={styles.content}>
        <Container maxWidth="md">
          <Typography variant="h6" gutterBottom align="left">
            Debate History
          </Typography>
        </Container>
        <div className={styles.tab}>
          <button
            className={activeTab === 0 ? styles.activeTab : styles.inactiveTab}
            onClick={() => {
              setActiveTab(0);
            }}
          >
            Mine
          </button>
          <button
            className={activeTab === 1 ? styles.activeTab : styles.inactiveTab}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            All
          </button>
        </div>
        <Container maxWidth="md"></Container>
      </div>
      <MobilePanel />
    </div>
  );
}

export default CompletedDebates;
