import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  List,
  ListItem,
  Typography,
  Container,
  Box,
} from "@mui/material";
import { tokenService } from "./../../../services/tokenService";

function DebateChat({ debateId, userId }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      let token = tokenService.getToken();

      if (!token) {
        const userData = tokenService.getUserData();
        token = userData?.token;
      }

      const response = await axios.get(
        `https://polemic.club/api/messages/${debateId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessages(response.data ?? []);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    try {
      let token = tokenService.getToken();

      if (!token) {
        const userData = tokenService.getUserData();
        token = userData?.token;
      }

      const messageData = {
        author: userId,
        debate: debateId,
        content: newMessage,
        chatType: "debater",
      };
      const response = await axios.post(
        `https://polemic.club/api/messages/${debateId}`,
        messageData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setNewMessage("");
      fetchMessages();
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Чат аргументов
      </Typography>
      <List style={{ maxHeight: "400px", overflow: "auto" }}>
        {messages?.map((message) => (
          <ListItem key={message._id} alignItems="flex-start" sx={{ py: 0.5 }}>
            <Box
              sx={{
                bgcolor: message.author._id === userId ? "#e3f2fd" : "#f8f9fa", // Light blue for user, light gray for others
                boxShadow: 1,
                p: 2,
                borderRadius: 2,
                width: "99%",
              }}
            >
              <Typography variant="body1">
                <strong>{message.author.name}:</strong> {message.content}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatTimestamp(message.timestamp)}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSendMessage();
        }}
        sx={{ mt: 2 }}
      >
        <TextField
          fullWidth
          variant="outlined"
          label="Новое сообщение"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          disabled={!newMessage.trim()}
        >
          Send
        </Button>
      </Box>
    </Container>
  );
}

export default DebateChat;
