import { useQuery } from "react-query";
import { apiInstance } from "../../api/instance";

const allDebates = async ({ queryKey }) => {
  const [_key, { page, limit }] = queryKey;
  const response = await apiInstance.get(
    `/admin/debates?page=${page}&limit=${limit}`
  );
  return response.data;
};

export const useAllDebates = (page = 1, limit = 10) => {
  return useQuery(["allDebates", { page, limit }], allDebates, {
    retry: 1,
    onError: (error) => console.error("Failed to fetch debates:", error),
    keepPreviousData: true,
  });
};
