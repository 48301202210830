import React from "react";
import { Navigate } from "react-router-dom";

import { AdminLayout } from "./Layout";

import { tokenService } from "../../../services/tokenService";

const getUserRole = () => {
  const userData = tokenService.getUserData();
  return userData?.role || null;
};

const AdminRoute = ({ children }) => {
  const userRole = getUserRole();

  if (userRole === "admin") {
    return <AdminLayout>{children}</AdminLayout>;
  }

  return <Navigate to="/" />;
};

export default AdminRoute;
