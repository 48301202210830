import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { tokenService } from "./../services/tokenService";

import { Alert, AlertTitle } from "@mui/material";

function JoinDebate() {
  const { debateId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [debateDetails, setDebateDetails] = useState({
    stakes: 0,
    topic: "",
    isPrivate: false,
    creatorName: "",
  });
  const [isUserConsent, setIsUserConsent] = useState(false);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const requestSent = useRef(false);

  useEffect(() => {
    let token = tokenService.getToken();

    if (!token) {
      const userData = tokenService.getUserData();
      token = userData?.token;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://polemic.club/api/debates/${debateId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setDebateDetails({
          stakes: response.data.stakes,
          topic: response.data.topic,
          isPrivate: response.data.isPrivate,
          creatorName: response.data.creator.name,
        });
        setIsDetailsLoaded(true);
      } catch (error) {
        console.error("Failed to fetch debate details:", error);
        setError("Failed to load debate details");
      }
    };

    fetchData();
  }, [debateId, isUserConsent, isDetailsLoaded]);

  useEffect(() => {
    if (!isUserConsent || requestSent.current) return;

    const token = new URLSearchParams(window.location.search).get("token");

    let authToken = "";

    if (tokenService.getToken()) {
      authToken = tokenService.getToken();
    } else {
      authToken = tokenService.getUserData()?.token;
    }

    if (!token || !authToken) {
      setError("Invalid invite link or user not logged in.");
      return;
    }

    const joinDebate = async () => {
      try {
        const response = await axios.post(
          `https://polemic.club/api/debates/${debateId}/join`,
          null,
          {
            headers: { Authorization: `Bearer ${authToken}` },
            params: { token },
          }
        );
        if (response.status === 200) {
          setSuccess(true);
          navigate(`/debates/${debateId}`);
        } else {
          throw new Error("Failed to join the debate");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data === "User is already a debater in this debate"
        ) {
          setAlertMessage(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert
                severity="error"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                You are already added to the debate: {error.message}
              </Alert>
            </div>
          );
        } else {
          setAlertMessage(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Alert
                severity="error"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                An error occurred while joining the debate: {error.message}
              </Alert>
            </div>
          );
        }
        console.error(error);
      }
    };

    joinDebate();
    requestSent.current = true;
  }, [debateId, isUserConsent, navigate]);

  if (!isDetailsLoaded) {
    return <div>Loading debate details...</div>;
  }

  return (
    <div>
      {alertMessage ? (
        alertMessage
      ) : !isUserConsent ? (
        <>
          <h1>{debateDetails.topic}</h1>
          <p>The stake for this debate is: {debateDetails.stakes} tokens</p>
          <p>Debate is {debateDetails.isPrivate ? "Private" : "Public"}</p>
          <p>Created by: {debateDetails.creatorName}</p>
          <button onClick={() => setIsUserConsent(true)}>
            Agree to Join Debate
          </button>
        </>
      ) : (
        <div>Joining debate...</div>
      )}
    </div>
  );
}

export default JoinDebate;
