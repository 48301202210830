import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useMe } from "../../../hooks/useMe";
import { constants } from "../../../shared/constants";
import StarIcon from "./../../../shared/static/icons/star.svg";
import UsdtIcon from "./../../../shared/static/icons/usdt.svg";
import Wallet from "./../../wallet/components/Wallet"; // Importing Wallet component

function UserDetails() {
  const navigate = useNavigate();

  const { data: user, isLoading, error } = useMe();

  const isDesktop = useMediaQuery({ query: constants.query });

  const becomeJudge = () => {
    console.log("Become a judge");
  };

  const judgePage = () => {
    navigate("/judge-page");
  };

  if (isLoading) {
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" align="center">
        Error: {error?.message}
      </Typography>
    );
  }

  const formattedBalance = parseFloat(user.balance).toFixed(6);

  return (
    <Container maxWidth="md">
      {isDesktop ? (
        <>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                User Details
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">
                    <strong>Name:</strong> {user.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Email:</strong> {user.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign="right">
                  <Typography variant="subtitle1" color="primary">
                    <strong>Balance:</strong> {formattedBalance}
                  </Typography>
                  <Typography variant="subtitle1" color="secondary">
                    <strong>Rank:</strong> {user.rank}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">
                    <p>Created:</p>{" "}
                    {new Date(user.createdAt).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign="right">
                  <Typography variant="subtitle1">
                    <p>Updated:</p>{" "}
                    {new Date(user.updatedAt).toLocaleDateString()}
                  </Typography>
                </Grid>
              </Grid>

              <Divider style={{ margin: "20px 0" }} />

              {/* Adding Wallet component */}
              <Wallet />
            </CardContent>
          </Card>

          {user?.role === "judge" ? (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ ml: 0 }}
              onClick={judgePage}
            >
              Judge's page
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ ml: 0 }}
              onClick={becomeJudge}
            >
              Become a judge
            </Button>
          )}
        </>
      ) : (
        <>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              fontWeight={isDesktop ? "normal" : "bold"}
              color={isDesktop ? "#000000" : "#31383D"}
              sx={{ alignSelf: isDesktop ? "center" : "flex-start" }}
            >
              Profile
            </Typography>
            <Box
              sx={{
                mt: 1,
                padding: "20px",
                borderRadius: 5,
                boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
                    -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
                    5px -5px 10px 0px rgba(204, 211, 216, 0.2),
                    -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
                backgroundColor: "#EEF6FC",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    background:
                      "linear-gradient(135deg, #7D92FA 0%, #4B68FA 100%)",
                    width: "54px",
                    height: "54px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: 0,
                    boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
                    -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
                    5px -5px 10px 0px rgba(204, 211, 216, 0.2),
                    -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
                  }}
                >
                  <Typography color="#ffffff">
                    user
                    {/* {user?.name[0]?.toUpperCase()} */}
                  </Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "5px",
                    }}
                  >
                    <Typography fontWeight={700}>name</Typography>
                    {/* <Typography fontWeight={700}>{user?.name}</Typography> */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        color="rgba(110, 125, 138, 1)"
                        variant="body2"
                      >
                        Rank
                      </Typography>
                      <img src={StarIcon} alt="star" />
                      <Typography variant="body2">rank</Typography>
                      {/* <Typography variant='body2'>{user?.rank}</Typography> */}
                    </Box>
                  </Box>
                  <Typography variant="body2">email</Typography>
                  {/* <Typography variant='body2'>{user?.email}</Typography> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    background:
                      "linear-gradient(135deg, #5FC7A9 0%, #53AE94 100%)",
                    width: "54px",
                    height: "54px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexShrink: 0,
                    boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
                    -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
                    5px -5px 10px 0px rgba(204, 211, 216, 0.2),
                    -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
                  }}
                >
                  <img src={UsdtIcon} alt="usdt" />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    align="left"
                    variant="body2"
                    color="rgba(110, 125, 138, 1)"
                  >
                    Balance
                  </Typography>
                  <Typography align="left" fontWeight={700}>
                    {formattedBalance}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color="rgba(110, 125, 138, 1)">
                  Created: {new Date(user.createdAt).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" color="rgba(110, 125, 138, 1)">
                  Updated: {new Date(user.updatedAt).toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ ml: 0 }}
            onClick={becomeJudge}
          >
            Become a judge
          </Button>
        </>
      )}
    </Container>
  );
}

export default UserDetails;
