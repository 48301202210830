import axios from "axios";

import { tokenService } from "../services/tokenService";

export const API_URL = "https://polemic.club/api/";

export const apiInstance = axios.create({
  baseURL: API_URL,
});

apiInstance.interceptors.request.use(async (config) => {
  let token = tokenService.getToken();

  if (!token) {
    const userData = await tokenService.getUserData();
    token = userData?.token;
  }

  const isValidToken = token && token !== "null";

  if (isValidToken) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      console.error("Unauthorized. Redirecting to login...");
    }

    return Promise.reject(error);
  }
);
