import { useQuery } from "react-query";
import { apiInstance } from "../api/instance";

const historyAll = async () => {
  const response = await apiInstance.get(`/debates/all-debaters`);
  return response.data;
};

export const useHistoryAll = () => {
  return useQuery("historyAll", historyAll, {
    retry: 1,
    onError: (error) => {
      console.error("Failed history all:", error);
    },
  });
};
