import { Typography } from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './MobilePanel.module.css';
import MenuIcons from './MenuIcons';

const MobilePanel = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles.container}>
      <nav>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to='/'>
              <MenuIcons
                iconIndex={1}
                stroke={pathname === '/' ? '#4b68fa' : '#31383d'}
              />
              <Typography
                variant='body2'
                className={
                  pathname === '/'
                    ? styles.navItemTextActive
                    : styles.navItemText
                }
              >
                Home
              </Typography>
            </NavLink>
          </li>
          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to='/new-debates'>
              <MenuIcons
                iconIndex={2}
                stroke={pathname === '/new-debates' ? '#4b68fa' : '#31383d'}
              />
              <Typography
                variant='body2'
                className={
                  pathname === '/new-debates'
                    ? styles.navItemTextActive
                    : styles.navItemText
                }
              >
                Debates
              </Typography>
            </NavLink>
          </li>
          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to='/user-page'>
              <MenuIcons
                iconIndex={3}
                fill={pathname === '/user-page' ? '#4b68fa' : '#31383d'}
              />
              <Typography
                variant='body2'
                className={
                  pathname === '/user-page'
                    ? styles.navItemTextActive
                    : styles.navItemText
                }
              >
                Profile
              </Typography>
            </NavLink>
          </li>
          <li className={styles.navItem}>
            <NavLink className={styles.navLink} to='/my-history'>
              <MenuIcons
                iconIndex={4}
                fill={pathname === '/my-history' ? '#4b68fa' : '#31383d'}
              />
              <Typography
                variant='body2'
                className={
                  pathname === '/my-history'
                    ? styles.navItemTextActive
                    : styles.navItemText
                }
              >
                History
              </Typography>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MobilePanel;