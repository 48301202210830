// src/services/tokenService.js
const TOKEN_KEY = "userToken";

const STORAGE_KEY = "userData";

export const tokenService = {
  getUserData: () => {
    const data = localStorage.getItem(STORAGE_KEY);
    return data ? JSON.parse(data) : {};
  },

  saveUserData: (data) => {
    if (data && typeof data === "object") {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    } else {
      console.error("Invalid data format. Expected an object.");
    }
  },

  // Методы для работы с токеном
  getToken: () => localStorage.getItem(TOKEN_KEY),
  saveToken: (token) => localStorage.setItem(TOKEN_KEY, token),
  removeToken: () => localStorage.removeItem(TOKEN_KEY),

  // Метод для очистки всех данных (может быть полезен при выходе из системы)
  clearAll: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(STORAGE_KEY);
  },
};
