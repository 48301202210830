import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Container,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Box,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { constants } from "../../../shared/constants";

function JudgePanel() {
  const [judge, setJudge] = useState(null);
  const [activeDebates, setActiveDebates] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const isDesktop = useMediaQuery({ query: constants.query });

  const handleJoinDebate = (debateId) => {
    console.log(`Join debate with ID: ${debateId}`);
  };

  useEffect(() => {
    const fetchJudgeData = async () => {
      try {
        const response = await axios.get(`https://polemic.club/api/judges/1`);
        console.log('fetchJudgeData', response.data)
        setJudge(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching judge data");
        setLoading(false);
        console.error(err);
      }
    };
    
    const fetchActiveDebates = async () => {
      try {
        const response = await axios.get(`https://polemic.club/api/debates/active`);
        console.log('fetchActiveDebates', response.data)
        setActiveDebates(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching active debates");
        setLoading(false);
        console.error(err);
      }
    };

    Promise.all([fetchJudgeData(), fetchActiveDebates()]);
  }, []);

  return (
    <Container maxWidth="md">
      {isDesktop ? (
        <>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Judge Panel
              </Typography>

              {judge && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  gap={2}
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>Rating: {judge.rating}</strong>
                  </Typography>

                  <Typography variant="h6" gutterBottom>
                    <strong>Total Earnings: {judge.totalEarnings}</strong>
                  </Typography>
                </Box>
              )}

              <Divider style={{ margin: "20px 0" }} />

              <Typography variant="h6" gutterBottom>
                <strong>Cases Resolved</strong>
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Topic</TableCell>
                      <TableCell>Winner</TableCell>
                      <TableCell>Loser</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {judge?.casesResolved.map((debate) => (
                      <TableRow key={debate._id}>
                        <TableCell>{debate.topic}</TableCell>
                        <TableCell>{debate.winner?.name}</TableCell>
                        <TableCell>{debate.loser?.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Divider style={{ margin: "20px 0" }} />

              <Typography variant="h6" gutterBottom>
                <strong>Dispute History</strong>
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Topic</TableCell>
                      <TableCell>Winner</TableCell>
                      <TableCell>Loser</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {judge?.disputeHistory.map((debate) => (
                      <TableRow key={debate._id}>
                        <TableCell>{debate.topic}</TableCell>
                        <TableCell>{debate.winner?.name}</TableCell>
                        <TableCell>{debate.loser?.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Divider style={{ margin: "20px 0" }} />

              <Typography variant="h6" gutterBottom>
                <strong>Active Debates</strong>
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Topic</TableCell>
                      <TableCell>Creator</TableCell>
                      <TableCell>Stakes</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeDebates.map((debate) => (
                      <TableRow key={debate._id}>
                        <TableCell>{debate.topic}</TableCell>
                        <TableCell>{debate.creator.name}</TableCell>
                        <TableCell>{debate.stakes}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleJoinDebate(debate._id)}
                          >
                            Join Debate
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </>
      ) : (
        <Typography variant="h6" gutterBottom>
          This view is not optimized for mobile devices.
        </Typography>
      )}
    </Container>
  );
}

export default JudgePanel;
