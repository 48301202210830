import React from 'react';
import styles from './Button.module.css';

const Button = ({ title, onClick, variant = 'dark', ...props }) => {
  return (
    <button onClick={onClick} className={styles[variant]} {...props}>
      {title}
    </button>
  );
};

export default Button;
