import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import {
  CircularProgress,
  Typography,
  Container,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { tokenService } from "./../../../services/tokenService";
import { constants } from "../../../shared/constants";
import { useMediaQuery } from "react-responsive";
import ArrowIcon from "./../../../shared/static/icons/arrow.svg";
import VsIcon from "./../../../shared/static/icons/VsIcon.svg";

function UserAsParticipant() {
  const [debates, setDebates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const isDesktop = useMediaQuery({ query: constants.query });

  useEffect(() => {
    const fetchDebates = async () => {
      let token = tokenService.getToken();

      if (!token) {
        const userData = tokenService.getUserData();
        token = userData?.token;
      }

      if (!token) {
        setError("Token not found. Please log in.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          "https://polemic.club/api/debates/debater?status=active",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setDebates(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching debates: " + err.message);
        setLoading(false);
      }
    };

    fetchDebates();
  }, []);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return isDesktop ? (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Active Debates I'm participating in:
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {debates.length > 0 ? (
          debates.map((debate) => (
            <Paper key={debate._id} elevation={3} sx={{ padding: 2 }}>
              <Typography variant="h6">
                <RouterLink
                  to={`/debates/${debate._id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Topic: {debate.topic}
                </RouterLink>
              </Typography>
              <Typography variant="body1">Status: {debate.status}</Typography>
              <Typography variant="body2">Participants:</Typography>
              <List dense>
                {debate.debaters.map((debater) => (
                  <ListItem key={debater._id}>
                    <ListItemText primary={debater.name} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          ))
        ) : (
          <Typography>
            You are not participating in any active debates yet.
          </Typography>
        )}
      </Box>
    </Container>
  ) : (
    <Container maxWidth="md">
      {debates.length > 0 ? (
        debates.map((debate) => (
          <Paper
            key={debate._id}
            elevation={3}
            sx={{
              padding: 2,
              borderRadius: "10px",
              mb: 2,
              background: "#EEF6FC",
              boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
              -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
              5px -5px 10px 0px rgba(204, 211, 216, 0.2),
              -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
            }}
          >
            <RouterLink
              component={RouterLink}
              to={`/debates/${debate._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
              underline="none"
            >
              <Typography variant="h6" color="#000000" align="left">
                {debate.topic}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    background: "rgba(63, 197, 0, 1)",
                    borderRadius: "30px",
                    padding: "5px 10px",
                  }}
                >
                  <Typography color="#ffffff">{debate.status}</Typography>
                </Box>
                <img src={ArrowIcon} alt="icon" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
              -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
              5px -5px 10px 0px rgba(204, 211, 216, 0.2),
              -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
                  borderRadius: "10px",
                  padding: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Box
                    sx={{
                      background:
                        "linear-gradient(135deg, #7D92FA 0%, #4B68FA 100%)",
                      width: "54px",
                      height: "54px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexShrink: 0,
                      boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
                    -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
                    5px -5px 10px 0px rgba(204, 211, 216, 0.2),
                    -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
                    }}
                  >
                    <Typography color="#ffffff">
                      {debate.debaters[0]?.name[0]?.toUpperCase()}
                    </Typography>
                  </Box>
                  <Typography color="rgba(49, 56, 61, 1)">
                    {debate.debaters[0]?.name}
                  </Typography>
                </Box>
                <img src={VsIcon} alt="icon" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Box
                    sx={{
                      background:
                        "linear-gradient(135deg, #7D92FA 0%, #4B68FA 100%)",
                      width: "54px",
                      height: "54px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexShrink: 0,
                      boxShadow: `5px 5px 13px 0px rgba(204, 211, 216, 0.9),
                    -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
                    5px -5px 10px 0px rgba(204, 211, 216, 0.2),
                    -5px 5px 10px 0px rgba(204, 211, 216, 0.2)`,
                    }}
                  >
                    <Typography color="#ffffff">
                      {debate.debaters[1]?.name[0]?.toUpperCase()}
                    </Typography>
                  </Box>
                  <Typography color="rgba(49, 56, 61, 1)">
                    {debate.debaters[1]?.name}
                  </Typography>
                </Box>
              </Box>
            </RouterLink>
          </Paper>
        ))
      ) : (
        <Typography>
          You are not participating in any active debates yet.
        </Typography>
      )}
    </Container>
  );
}

export default UserAsParticipant;
