import { useQuery } from "react-query";
import { apiInstance } from "../api/instance";

const fetchDebateDetails = async (debateId) => {
  const response = await apiInstance.get(`/debates/${debateId} `);
  return response.data;
};

export const useDebateDetail = (debateId) => {
  return useQuery(
    ["debateDetails", debateId],
    () => fetchDebateDetails(debateId),
    {
      retry: 1,
      enabled: !!debateId,
      onError: (error) =>
        console.error("Failed to fetch debate details:", error),
    }
  );
};
