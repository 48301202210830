import React, { useState } from 'react';

import UserDebates from './../modules/users/components/UserDebates';
import UserAsParticipant from './../modules/users/components/UserAsParticipant';
import { useMediaQuery } from 'react-responsive';
import { constants } from '../shared/constants';
import styles from './MyActiveDebates.module.css';
import MobilePanel from '../shared/ui/MobilePanel';

const MyActiveDebates = () => {
  const isDesktop = useMediaQuery({ query: constants.query });
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={styles.container}>
      {isDesktop && <h1>All active debates with my participation:</h1>}
      {isDesktop ? (
        <div>
          <br></br>
          <UserDebates />
          <br></br>
          <br></br>
          <UserAsParticipant />
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.tab}>
            <button
              className={
                activeTab === 0 ? styles.activeTab : styles.inactiveTab
              }
              onClick={() => {
                setActiveTab(0);
              }}
            >
              Mine
            </button>
            <button
              className={
                activeTab === 1 ? styles.activeTab : styles.inactiveTab
              }
              onClick={() => {
                setActiveTab(1);
              }}
            >
              I'm participating
            </button>
          </div>
          {activeTab === 0 ? <UserDebates /> : <UserAsParticipant />}
        </div>
      )}
      {!isDesktop && <MobilePanel />}
    </div>
  );
};

export default MyActiveDebates;