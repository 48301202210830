import { useQuery } from "react-query";
import { apiInstance } from "../../api/instance";

const allUsers = async (page = 1, limit = 5) => {
  const response = await apiInstance.get(`/admin/users`, {
    params: { page, limit },
  });
  return response.data;
};

export const useAllUsers = (page = 1, limit = 5) => {
  return useQuery(["allUsers", page, limit], () => allUsers(page, limit), {
    retry: 1,
    onError: (error) => console.error("Failed to fetch users:", error),
    keepPreviousData: true,
  });
};
