import { useMutation } from "react-query";
import { apiInstance } from "../api/instance";

const inviteUserToJugde = async ({ debateId }) => {
  const responce = await apiInstance.post(`/debates/${debateId}/invite-judge`);

  return responce.data;
};

export const useInviteUserToJudge = () => {
  return useMutation(inviteUserToJugde, {
    onError: (error) => {
      console.error("Error invite judge:", error.message);
    },
  });
};
