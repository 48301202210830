import { useMutation } from "react-query";
import { apiInstance } from "../api/instance";

export const disputeJudges = async ({ debateId }) => {
  const response = await apiInstance.post(
    `/debates/${debateId}/dispute-judge/`
  );

  return response.data;
};

export const useDisputeJudges = () => {
  return useMutation(disputeJudges, {
    onError: (error) => {
      console.error("Failed dispute judge:", error);
    },
  });
};
