import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import HeaderPanel from "./shared/ui/HeaderPanel";
import HomePage from "./pages/HomePage";
import "./styles/App.css";
import "./styles/global.css";
import ClientRegister from "./pages/ClientRegister";
import Debates from "./pages/Debates";
import UserPage from "./pages/UserPage";
import MyActiveDebates from "./pages/MyActiveDebates";
import DebateDetails from "./pages/DebateDetails";
import JoinDebate from "./pages/JoinDebate";
import DebateParticipantDetails from "./pages/DebateParticipantDetails";
import CompletedDebates from "./pages/CompletedDebates";
import JudgePage from "./pages/JudgePage";
import NotificationPage from "./pages/NotificationPage";
import { ToastContainer, toast } from "react-toastify";
import { UserLayout } from "./modules/users";

import HistoryAll from "./pages/HistoryAll";
import { useMediaQuery } from "react-responsive";
import { constants } from "./shared/constants";
import { tokenService } from "./services/tokenService";
import { QueryProvider } from "./store/provider";

import { useJudgeAcceptInvite } from "./hooks";

import { AdminRoutes } from "./routes/admin";

import "react-toastify/dist/ReactToastify.css";

import io from "socket.io-client";

const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          margin: "8px",
        },
      },
    },
  },
});

const socket = io("http://localhost:4000");

function App() {
  const isDesktop = useMediaQuery({ query: constants.query });

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.emit("identity", { userId: "66d2259401e323365cbbd111" });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    socket.on("notification", (data) => {
      console.log("Received notification:", data);
      toast.info(data?.message);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("notification");
    };
  }, []);

  return (
    <QueryProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />{" "}
          <div className="App">
            <Routes>
              <Route element={<UserLayout isDesktop={isDesktop} />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/new-debates" element={<Debates />} />
                <Route path="/client-register" element={<ClientRegister />} />
                <Route path="/user-page" element={<UserPage />} />
                <Route
                  path="/user-active-debates"
                  element={<MyActiveDebates />}
                />
                <Route path="/debates/:debateId" element={<DebateDetails />} />
                <Route
                  path="/debates/:debateId/join"
                  element={<JoinDebate />}
                />
                <Route
                  path="/debate-participant-details/:debateId"
                  element={<DebateParticipantDetails />}
                />
                <Route path="/my-history" element={<CompletedDebates />} />
                <Route path="/history-all" element={<HistoryAll />} />
                <Route path="/judge-page" element={<JudgePage />} />
                <Route path="/notification" element={<NotificationPage />} />
              </Route>

              <Route path="/admin/*" element={<AdminRoutes />} />
            </Routes>
            <ToastContainer position="bottom-right" autoClose={3000} />
          </div>
        </ThemeProvider>
      </Router>
    </QueryProvider>
  );
}

export default App;
