import React from 'react';

import CreateDebate from './../modules/disputes/components/CreateDebate';
import { useMediaQuery } from 'react-responsive';
import { constants } from '../shared/constants';
import MobilePanel from '../shared/ui/MobilePanel';
import styles from './Debates.module.css';

const Debates = () => {
  const isDesktop = useMediaQuery({ query: constants.query });

  return (
    <div className={styles.container}>
      {isDesktop && <h1>Debates page</h1>}
      {isDesktop ? (
        <CreateDebate />
      ) : (
        <div className={styles.content}>
          <CreateDebate />
        </div>
      )}
      {!isDesktop && <MobilePanel />}
    </div>
  );
};

export default Debates;
