import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { apiInstance } from "../api/instance";

const jugdeAcceptInvite = async ({ debateId }) => {
  const response = await apiInstance.post(`/judges/${debateId}/accept-invit`);

  return response.data;
};

export const useJudgeAcceptInvite = () => {
  const navigate = useNavigate();

  return useMutation(jugdeAcceptInvite, {
    onSuccess: (data) => {
      navigate(`/debates/${data.debateId}`);
    },
    onError: (error) => {
      console.error("Failed accept invite judge:", error);
    },
  });
};
