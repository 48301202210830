import { Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../axios";
import { tokenService } from "../services/tokenService";
import FacebookLoginButton from "./FacebookLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";

function ClientRegister() {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    role: "basic",
    termsAgreed: true,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
  const [isFacebookScriptLoaded, setIsFacebookScriptLoaded] = useState(false);
  const [isAppleScriptLoaded, setIsAppleScriptLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const googleScript = document.createElement("script");
    googleScript.src = "https://accounts.google.com/gsi/client";
    googleScript.async = true;
    googleScript.defer = true;
    googleScript.onload = () => setIsGoogleScriptLoaded(true);
    document.body.appendChild(googleScript);

    const facebookScript = document.createElement("script");
    facebookScript.src = "https://connect.facebook.net/en_US/sdk.js";
    facebookScript.async = true;
    facebookScript.defer = true;
    facebookScript.onload = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v15.0",
      });
      setIsFacebookScriptLoaded(true);
    };
    document.body.appendChild(facebookScript);

    const appleScript = document.createElement("script");
    appleScript.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    appleScript.async = true;
    appleScript.defer = true;
    appleScript.onload = () => setIsAppleScriptLoaded(true);
    document.body.appendChild(appleScript);

    return () => {
      document.body.removeChild(googleScript);
      document.body.removeChild(facebookScript);
      document.body.removeChild(appleScript);
    };
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setUserData({ ...userData, [name]: type === "checkbox" ? checked : value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!userData.termsAgreed) {
      setErrorMessage("You must agree to the terms of use.");
      return;
    }
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const response = await axios.post(
        "https://polemic.club/api/users/register",
        userData
      );
      handleSuccessfulAuth(response.data);
    } catch (error) {
      handleAuthError(error);
    }
  };

  const handleGoogleSuccess = (data) => {
    handleSuccessfulAuth(data);
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Sign-In Error:", error);
    setErrorMessage("Error signing in with Google");
  };

  const handleFacebookSuccess = (data) => {
    handleSuccessfulAuth(data);
  };

  const handleFacebookFailure = (error) => {
    console.error("Facebook Sign-In Error:", error);
    setErrorMessage(error);
  };

  const handleAppleSuccess = (data) => {
    handleSuccessfulAuth(data);
  };

  const handleAppleFailure = (error) => {
    console.error("Apple Sign-In Error:", error);
    setErrorMessage("Error signing in with Apple");
  };

  const handleSuccessfulAuth = (data) => {
    tokenService.saveToken(data.token);
    setSuccessMessage("Login successful!");
    setTimeout(() => {
      navigate("/user-page");
    }, 1000);
  };

  const handleAuthError = (error) => {
    console.error("Authentication error:", error);
    if (error.response && error.response.data && error.response.data.message) {
      setErrorMessage(error.response.data.message);
    } else {
      setErrorMessage("Authentication error");
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <h1>Client Registration</h1>

      <Divider style={{ margin: "20px 0" }} />
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        Sign in with:{" "}
      </Typography>
      <div style={{ width: "100%", maxWidth: "300px", margin: "0 auto" }}>
        {console.log("ClientRegister: Rendering GoogleLoginButton")}
        {isGoogleScriptLoaded ? (
          <GoogleLoginButton
            onSuccess={handleGoogleSuccess}
            onFailure={handleGoogleFailure}
          />
        ) : (
          <div>Loading Google button...</div>
        )}
      </div>

      <div style={{ width: "100%", maxWidth: "300px", margin: "20px auto" }}>
        {console.log("ClientRegister: Rendering FacebookLoginButton")}
        {isFacebookScriptLoaded ? (
          <FacebookLoginButton
            onSuccess={handleFacebookSuccess}
            onFailure={handleFacebookFailure}
          />
        ) : (
          <div>Loading Facebook button...</div>
        )}
      </div>

      {/* <div style={{ width: '100%', maxWidth: '300px', margin: '20px auto' }}>
        {console.log('ClientRegister: Rendering AppleLoginButton')}
        {isAppleScriptLoaded ? (
          <AppleLoginButton
            onSuccess={handleAppleSuccess}
            onFailure={handleAppleFailure}
          />
        ) : (
          <div>Loading Apple button...</div>
        )}
      </div> */}

      {errorMessage && (
        <Typography color="error" style={{ marginTop: "10px" }}>
          {errorMessage}
        </Typography>
      )}
      {successMessage && (
        <Typography color="primary" style={{ marginTop: "10px" }}>
          {successMessage}
        </Typography>
      )}
    </div>
  );
}

export default ClientRegister;
