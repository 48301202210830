import React from 'react';
import styles from './Icon.module.css';

const Icon = ({ icon, width, height, borderRadius, ...props }) => {
  return (
    <div
      style={{ width, height, borderRadius, ...props }}
      className={styles.icon}
    >
      <img src={icon} alt='icon' />
    </div>
  );
};

export default Icon;
