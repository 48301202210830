import React, { useEffect, useRef } from "react";
import axios from "../axios";
import { tokenService } from "../services/tokenService";

const FacebookLoginButton = ({ onSuccess, onFailure }) => {
  const buttonRef = useRef(null);
  const appId = process.env.REACT_APP_FACEBOOK_APP_ID;

  useEffect(() => {
    console.log("FacebookLoginButton: useEffect triggered");
    console.log("FacebookLoginButton: App ID:", appId);

    const loadFacebookScript = () => {
      console.log("FacebookLoginButton: Loading Facebook SDK");
      const script = document.createElement("script");
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        console.log("FacebookLoginButton: Facebook SDK loaded");
        initializeFacebookSignIn();
      };
      script.onerror = () => {
        console.error("FacebookLoginButton: Error loading Facebook SDK");
        onFailure("Error loading Facebook SDK");
      };
      document.body.appendChild(script);
    };

    const initializeFacebookSignIn = () => {
      console.log("FacebookLoginButton: Initializing Facebook SDK");
      window.FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: "v15.0",
      });
      renderFacebookButton();
    };

    const renderFacebookButton = () => {
      if (buttonRef.current) {
        buttonRef.current.onclick = handleFacebookLogin;
      } else {
        console.error("FacebookLoginButton: Button ref not found");
      }
    };

    loadFacebookScript();

    return () => {
      console.log("FacebookLoginButton: Component unmounted");
    };
  }, [appId, onSuccess, onFailure]);

  const handleFacebookLogin = () => {
    console.log("FacebookLoginButton: Starting Facebook login process");
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          handleAuthSuccess(response.authResponse);
        } else {
          console.error(
            "FacebookLoginButton: User cancelled login or did not fully authorize."
          );
          onFailure("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "public_profile,email" }
    );
  };

  const handleAuthSuccess = async (authResponse) => {
    console.log("FacebookLoginButton: Facebook login successful", authResponse);
    const { accessToken } = authResponse;
    try {
      const res = await axios.post(
        "https://polemic.club/api/users/auth/facebook",
        {
          token: accessToken,
        }
      );
      if (res.status === 200) {
        console.log(
          "FacebookLoginButton: User successfully authenticated:",
          res.data
        );
        tokenService.saveToken(res.data.token);

        onSuccess(res.data);
      } else {
        console.error("FacebookLoginButton: Authentication error:", res.data);
        onFailure(res.data);
      }
    } catch (error) {
      console.error("FacebookLoginButton: Error during authentication:", error);
      onFailure(error.message);
    }
  };

  return (
    <div
      ref={buttonRef}
      style={{
        width: "100%",
        maxWidth: "300px",
        height: "40px",
        border: "1px solid #3b5998",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        fontSize: "14px",
        background: "#3b5998",
        color: "white",
        letterSpacing: "0.25px",
        fontWeight: "400px",
        borderRadius: "4px",
      }}
    >
      Continue with Facebook
    </div>
  );
};

export default FacebookLoginButton;
